exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-attic-insulation-and-flooring-jsx": () => import("./../../../src/pages/attic-insulation-and-flooring.jsx" /* webpackChunkName: "component---src-pages-attic-insulation-and-flooring-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-painting-jsx": () => import("./../../../src/pages/painting.jsx" /* webpackChunkName: "component---src-pages-painting-jsx" */),
  "component---src-pages-pressure-washing-jsx": () => import("./../../../src/pages/pressure-washing.jsx" /* webpackChunkName: "component---src-pages-pressure-washing-jsx" */),
  "component---src-pages-roofing-chimney-repairs-jsx": () => import("./../../../src/pages/roofing/chimney-repairs.jsx" /* webpackChunkName: "component---src-pages-roofing-chimney-repairs-jsx" */),
  "component---src-pages-roofing-dry-verge-systems-jsx": () => import("./../../../src/pages/roofing/dry-verge-systems.jsx" /* webpackChunkName: "component---src-pages-roofing-dry-verge-systems-jsx" */),
  "component---src-pages-roofing-fascia-soffit-jsx": () => import("./../../../src/pages/roofing/fascia-soffit.jsx" /* webpackChunkName: "component---src-pages-roofing-fascia-soffit-jsx" */),
  "component---src-pages-roofing-flat-roofs-jsx": () => import("./../../../src/pages/roofing/flat-roofs.jsx" /* webpackChunkName: "component---src-pages-roofing-flat-roofs-jsx" */),
  "component---src-pages-roofing-guttering-jsx": () => import("./../../../src/pages/roofing/guttering.jsx" /* webpackChunkName: "component---src-pages-roofing-guttering-jsx" */),
  "component---src-pages-roofing-index-jsx": () => import("./../../../src/pages/roofing/index.jsx" /* webpackChunkName: "component---src-pages-roofing-index-jsx" */),
  "component---src-pages-roofing-lead-valleys-jsx": () => import("./../../../src/pages/roofing/lead-valleys.jsx" /* webpackChunkName: "component---src-pages-roofing-lead-valleys-jsx" */),
  "component---src-pages-roofing-new-roofs-jsx": () => import("./../../../src/pages/roofing/new-roofs.jsx" /* webpackChunkName: "component---src-pages-roofing-new-roofs-jsx" */),
  "component---src-pages-roofing-ridge-pointing-jsx": () => import("./../../../src/pages/roofing/ridge-pointing.jsx" /* webpackChunkName: "component---src-pages-roofing-ridge-pointing-jsx" */),
  "component---src-pages-roofing-roof-repairs-jsx": () => import("./../../../src/pages/roofing/roof-repairs.jsx" /* webpackChunkName: "component---src-pages-roofing-roof-repairs-jsx" */),
  "component---src-pages-testimonials-jsx": () => import("./../../../src/pages/testimonials.jsx" /* webpackChunkName: "component---src-pages-testimonials-jsx" */)
}

